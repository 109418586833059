.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
}

.scroll-to-top.visible {
    opacity: 1;
    pointer-events: auto;
}

.scroll-button {
    background-color: #007BFF;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 15px 20px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
