footer {
    width: 100%;
    flex-shrink: 0;
    bottom: 0;
    margin-top: 2rem;
}

.footer__container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 3rem auto 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 1800px;
    align-items: center;
    gap: 80px;
}

.footer__organization-container {
    text-align: center;
    margin-bottom: 20px;
}

.footer__organization-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    width: 360px;
    margin: 0 auto;
}

.footer__address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 280px;
    text-align: center;
}

@media screen and (max-width: 1069px) {
    .footer__container {
        flex-direction: column;
        gap: unset;
    }
    .footer__address-container {
        width: 300px;
        padding: 10px 15px;
    }
    .footer__organization-text {
        width: 400px;
    }
}

@media screen and (max-width: 768px) {
    .footer__address-container {
        width: 350px;
        padding: 10px 10px;
    }
}

@media screen and (max-width: 480px) {
    .footer__address-container {
        width: 290px;
        padding: 10px 5px;
    }

    .footer__organization-text {
        font-size: 16px;
        width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .footer__address-container {
        width: 200px;
    }
}

.footer__organization-address {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
}

.footer__link-list {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.footer__link {
    color: black;
    text-decoration: none;
    display: inline-block;
    transition: transform 0.3s ease-in-out;
}

.footer__icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transition: transform 1s ease-in-out;
}

.footer__icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer__link:hover .footer__icon {
    transform: rotate(360deg);
}

.footer__link:hover {
    transform: scale(1.2);
}
.footer__route-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.footer__route-link:hover {
    background-color: #0056b3;
    border-color: #0056b3;
    color: #fff;
}
