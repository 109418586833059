.educationLevelSelection__container {
    margin-top: 120px;
    text-align: center;
}

.educationLevelSelection__container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.educationLevelSelection__list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-items: center;
}

@media (min-width: 600px) {
    .educationLevelSelection__list {
        grid-template-columns: 1fr 1fr;
    }

    .educationLevelSelection__list li:nth-child(odd):last-child {
        grid-column: 1 / -1;
        justify-self: center;
    }
}

.educationLevelSelection__list li {
    width: 100%;
    max-width: 300px;
}

.educationLevelSelection__list li a {
    text-decoration: none;
    color: white;
    background: linear-gradient(270deg, rgba(34, 118, 232, 0.8) 0%, rgba(34, 118, 232, 0.9) 22%, rgba(34, 118, 232, 0.95) 59%, rgba(34, 118, 232, 1) 100%);
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    display: block;
    text-align: center;
    width: 100%;
}

.educationLevelSelection__list li a:hover {
    background: linear-gradient(270deg, rgba(34, 118, 232, 1) 0%, rgba(34, 118, 232, 0.95) 22%, rgba(34, 118, 232, 0.9) 59%, rgba(34, 118, 232, 0.8) 100%);
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
