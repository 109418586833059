.not-found {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found__title {
    font-weight: 700;
    font-size: 340px;
    line-height: normal;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #2276E8;
}

.not-found__text {
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.not-found__link {
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    color: #4285F4;
    text-decoration: none;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-top: 10px;
}

.not-found__link:hover {
    opacity: 0.7;
}

@media screen and (max-width: 767px) {
    .not-found {
        padding-top: 0;
    }

    .not-found__title {
        font-size: 150px;
        line-height: 1;
        margin-bottom: 20px;
    }

    .not-found__text, .not-found__link {
        font-size: 18px;
        line-height: 1;
    }
}
