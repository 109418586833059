.burger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 1111;
    font-family: Arial, sans-serif;
}

.burger__container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    min-width: 320px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    box-sizing: border-box;
    z-index: 1111;
}

.burger__button_close {
    border: none;
    background: none;
    background-image: url('../../images/closeBtn__Burger.svg');
    width: 32px;
    height: 32px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    cursor: pointer;
}

.burger__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    margin: 0 auto;
}

.burger__menu_link {
    color: blue;
    text-align: center;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.burger__menu_link:hover {
    opacity: 0.7;
}

.burger__menu-link-active {
    padding-bottom: 4px;
    border-bottom: 2px solid #000;
    transition: 0.3s ease;
}

.burger__menu_link-down {
    margin-bottom: 80px;
}

@media (max-width: 768px) {
    .burger__container {
        max-width: unset;
    }
}

@media (max-width: 400px) {
    .burger__menu {
        gap: 15px;
    }

    .burger__button_profile {
        margin-bottom: 110px;
    }

    .burger__menu_link {
        font-size: 24px;
    }
}