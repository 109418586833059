.dropdownMenu__container {
    display: flex;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
}

.dropdown-toggle::after {
    content: unset !important;
}

.dropdown-toggle-custom {
    background: none;
    border: none;
    color: #2276E8;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.dropdown-toggle-custom:hover {
    color: #0056b3;
    text-decoration: underline !important;
}

.arrow-down {
    font-size: 12px;
    transition: transform 0.5s ease;
    display: inline-block;
}

.dropdown-toggle-custom[aria-expanded="true"] .arrow-down {
    transform: rotate(180deg);
}

.dropdownMenu__navigation__dropdown .dropdown-menu {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    position: relative;
}

.dropdownMenu__navigation__dropdown .dropdown-menu::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: linear-gradient(270deg, rgba(34, 118, 232, 0.8) 0%, rgba(32, 127, 234, 0.8) 22%, rgba(27, 152, 240, 0.8) 59%, rgba(21, 189, 249, 0.8) 100%);
    position: absolute;
    top: -3px;
    left: 0;
    border-radius: 50px 50px 0 0;
}

.dropdownMenu__navigation__dropdown .dropdown-item {
    color: #2276E8;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 4px;
}

.dropdownMenu__navigation__dropdown .dropdown-item:hover {
    background-color: rgba(34, 118, 232, 0.1);
    color: #0056b3;
}

.dropdown-toggle-custom.dropdown-toggle.btn.btn-link {
    font-size: 18px;
}
