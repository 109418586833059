.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

.page-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

.container {
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container{
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px
  }
}