.DocumentLinksPage__container {
    padding: 20px;
    margin-top: 100px;
    width: clamp(150px, 100vw, 1000px);
    max-width: calc(100% - 40px);
}

.DocumentLinksPage__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.DocumentLinksPage__list li {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px;
    background: #f9f9f9;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.DocumentLinksPage__list li a,
.DocumentLinksPage__list li .external-link-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #2276E8;
}

.DocumentLinksPage__list li:hover {
    background-color: #e8f0ff;
    transform: translateY(-2px);
}

.DocumentLinksPage__content-container .accordion {
    margin-top: 10px;
    margin-bottom: 10px;
}

.DocumentLinksPage__content-container .accordion-button {
    background-color: #2276E8;
    color: white;
    border: none;
    border-radius: 1px;
    padding: 15px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.DocumentLinksPage__content-container .accordion-button:not(.collapsed) {
    background-color: #2463b8;
    color: white;
}

.DocumentLinksPage__content-container .accordion-body {
    background-color: #f5f5f5;
    padding: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.DocumentLinksPage__content-container .accordion-button::after {
    display: none;
}

.DocumentLinksPage__content-container .accordion-button::before {
    content: '\2212';
    color: white;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    margin-left: 10px;
    margin-right: 10px;
}

.DocumentLinksPage__content-container .accordion-button.collapsed::before {
    content: '\002B';
}

.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 500ms, transform 500ms;
}

.DocumentLinksPage__content-container table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
}

.DocumentLinksPage__content-container th,
.DocumentLinksPage__content-container td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.DocumentLinksPage__content-container th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.DocumentLinksPage__content-container th:first-child,
.DocumentLinksPage__content-container td:first-child {
    border-right: 2px solid #ddd;
}

.DocumentLinksPage__content-container tr:not(:last-child) td {
    border-bottom: 2px solid #ddd;
}

.DocumentLinksPage__list a {
    color: #2276E8;
    text-decoration: none;
    transition: color 0.3s ease;
}

.DocumentLinksPage__list a:hover {
    text-decoration: underline;
}