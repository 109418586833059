.decorative__border {
    height: 7px;
    background: linear-gradient(270deg, #2276E8 0%, #207FEA 22%, #1B98F0 59%, #15BDF9 100%);
    border-radius: 2rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 800px;
}

@media screen and (max-width: 1030px) {
    .decorative__border {
        max-width: 600px;
        height: 5px;
    }
}

@media screen and (max-width: 768px) {
    .decorative__border {
        max-width: 400px;
        height: 4px;
    }
}

@media screen and (max-width: 480px) {
    .decorative__border {
        max-width: 350px;
    }
}

@media screen and (max-width: 370px) {
    .decorative__border {
        max-width: 270px;
    }
}