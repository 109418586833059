.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem calc(1rem + 20px - (100vw - 100%)) 1rem 2rem;
    gap: 20px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header__logo {
    height: auto;
    max-width: 100%;
    min-width: 230px;
}

.header__burger:hover {
    opacity: 0.7;
}

.header__icon-container {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 100%;
}

.header__button {
    width: 2.3rem;
    height: 2.3rem;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: inherit;
    border: none;
}

.header__button:hover {
    opacity: 0.7;
}

.header__button_search {
    background-image: url('../../images/search.svg');
}

.header__button_calendar {
    background-image: url('../../images/calendar.svg');
}

.header__button_call {
    background-image: url('../../images/phone.svg');
}

.header__button_gos {
    background-image: url('../../images/header__gos_logo.svg');
}

.header__button_burger {
    background-image: url('../../images/burger.svg');
    display: none;
}

.header__navigation {
    display: flex;
    flex-direction: column;
}

.header__navigation__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
}

.header__navigation__link {
    color: #2276E8;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-decoration: none !important;
    white-space: nowrap;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.header__navigation__link:hover {
    transform: scale(1.05);
    opacity: 0.8;
    cursor: pointer;
}

@media (max-width: 1150px) {
    .header__navigation__link {
        font-size: 15px;
    }
}

@media (max-width: 1285px) {
    .header__navigation {
        display: none;
    }

    .header__button_burger {
        display: unset;
    }

    .header__button_calendar {
        display: none;
    }

    .header__container {
        gap: 10px;
    }
}

@media (max-width: 768px) {

    .header__logo {
        width: 1.5rem;
    }

    .header__button {
        width: 1.7rem;
        height: 1.7rem;
    }

    .header__icon-container {
        gap: 7px;
    }
}

@media (max-width: 425px) {
    .header__logo {
        min-width: 150px;
        width: 190px;
    }

    .header__button {
        width: 1.3rem;
        height: 1.3rem;
    }

    .header__container {
        padding: 1rem;
    }
}